<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="saveConfig()"
            >
              <v-row>
                <v-col cols="2" class="pb-0">
                  <v-text-field
                    v-model="cantDiasBackup"
                    label="Días de plazo"
                    dense
                    clearable
                    outlined
                    :rules="
                      cantDiasBackup !== 0
                        ? rules.required.concat(
                            rules.maxLength(cantDiasBackup, 2),
                            rules.minLength(cantDiasBackup, 1)
                          )
                        : []
                    "
                    v-mask="'##'"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="pb-0 mt-2 pl-0">
                  <v-tooltip right max-width="40%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      Cantidad de días transcurridos desde la última copia de
                      seguridad realizada a partir del cual se mostrará una
                      alerta informando sobre esta situación al momento de
                      iniciar sesión en el sitio. En caso de ser 0 no hará el
                      control.
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!isFormValid || cantDiasBackup === ''"
              form="form"
              color="primary"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionesGenerales",
  directives: { mask },
  props: {
    itemConfig: {
      type: Object
    }
  },
  components: {
    GoBackBtn,
    PageHeader,
    Ayuda
  },
  data: () => ({
    rules: rules,
    title: "Configuraciones generales",
    routeToGo: "ConfiguracionAdmSistema",
    optionCode: enums.webSiteOptions.CONFIGURACIONES_GENERALES,
    listIcon: enums.icons.UL,
    infoIcon: enums.icons.SNB_INFO,
    calendarIcon: enums.icons.CALENDAR,
    baseDatoNom: "",
    cantDiasBackup: null,
    habCarga: false,
    habListado: false,
    isFormValid: false,
    allowedActions: null,
    canGoToLogConfigsGenerales: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.CONFIGURACIONES_GENERALES
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    await this.getConfiguracionGral();
  },

  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getConfigGral: "AdministracionSistema/getConfigGral",
      saveConfigGral: "AdministracionSistema/saveConfigGral"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .LOG_CONFIGURACIONES_GENERALES:
            this.canGoToLogConfigsGenerales = true;
            break;
          default:
            break;
        }
      });
    },
    async getConfiguracionGral() {
      const response = await this.getConfigGral();
      this.cantDiasBackup = response.cantidadDiasControlBackup;
    },
    async saveConfig() {
      this.isFormValid = false;
      const data = {
        cantidadDiasControlBackup: this.cantDiasBackup
      };
      try {
        const response = await this.saveConfigGral(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    goToLogConfigsGenerales() {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({ name: "LogConfiguracionesGenerales" });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
