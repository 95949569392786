var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"label":"Días de plazo","dense":"","clearable":"","outlined":"","rules":_vm.cantDiasBackup !== 0
                      ? _vm.rules.required.concat(
                          _vm.rules.maxLength(_vm.cantDiasBackup, 2),
                          _vm.rules.minLength(_vm.cantDiasBackup, 1)
                        )
                      : []},model:{value:(_vm.cantDiasBackup),callback:function ($$v) {_vm.cantDiasBackup=$$v},expression:"cantDiasBackup"}})],1),_c('v-col',{staticClass:"pb-0 mt-2 pl-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" Cantidad de días transcurridos desde la última copia de seguridad realizada a partir del cual se mostrará una alerta informando sobre esta situación al momento de iniciar sesión en el sitio. En caso de ser 0 no hará el control. ")])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid || _vm.cantDiasBackup === '',"form":"form","color":"primary"}},[_vm._v(" Actualizar ")])],1)],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }